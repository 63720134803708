const React = require("react");
const { QueryClient, QueryClientProvider } = require("react-query");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

exports.wrapRootElement = ({ element }) => {
  require("./src/styles/index.scss"); // adding scss to every page
  return (
    <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
  );
};
