// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-email-otp-js": () => import("./../../../src/pages/email-otp.js" /* webpackChunkName: "component---src-pages-email-otp-js" */),
  "component---src-pages-forgot-js": () => import("./../../../src/pages/forgot.js" /* webpackChunkName: "component---src-pages-forgot-js" */),
  "component---src-pages-forgot-success-js": () => import("./../../../src/pages/forgot-success.js" /* webpackChunkName: "component---src-pages-forgot-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-register-success-js": () => import("./../../../src/pages/register-success.js" /* webpackChunkName: "component---src-pages-register-success-js" */),
  "component---src-pages-reset-js": () => import("./../../../src/pages/reset.js" /* webpackChunkName: "component---src-pages-reset-js" */),
  "component---src-pages-reset-success-js": () => import("./../../../src/pages/reset-success.js" /* webpackChunkName: "component---src-pages-reset-success-js" */)
}

